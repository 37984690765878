import * as React from "react";
import {
  PropertyWhatsAround as SharedUiPropertyWhatsAround,
  WhatsAroundLayout,
  PropertyWhatsAroundMapPoisEditorialView,
} from "@shared-ui/lodging-property-details";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { RefTarget } from "@shared-ui/ref-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { WhatsAroundProps } from "./typings";
import { useExperiment } from "@shared-ui/experiment-context";

const WrappedPropertyWhatsAround = PropertyBexApiWrapper(SharedUiPropertyWhatsAround);
const WrappedPropertyWhatsAroundMapPoisEditorialView = PropertyBexApiWrapper(PropertyWhatsAroundMapPoisEditorialView);

export const PropertyWhatsAround: React.FC<WhatsAroundProps> = (props: WhatsAroundProps) => {
  const { templateComponent, context } = props;
  const { disableMap, enableExpando, showBorder } = templateComponent.config;
  const { bucket: enableMapPoisEditorialView } = useExperiment(
    "Repositioning_map_and_location_insights_to_About_the_area",
    true
  ).exposure;

  return (
    <UitkSpacing
      margin={
        showBorder
          ? {
              small: { block: "six", inline: "three" },
              large: { block: "six", inline: "unset" },
            }
          : { block: "three" }
      }
    >
      <RefTarget name="Location" tag="section">
        {enableMapPoisEditorialView ? (
          <UitkSpacing margin={{ blockstart: "six" }}>
            <div>
              <WrappedPropertyWhatsAroundMapPoisEditorialView context={context} showBorder={showBorder} />
            </div>
          </UitkSpacing>
        ) : (
          <WrappedPropertyWhatsAround
            context={context}
            view={disableMap ? WhatsAroundLayout.DISABLE_MAP : undefined}
            isExpando={enableExpando}
            showBorder={showBorder}
          />
        )}
      </RefTarget>
    </UitkSpacing>
  );
};
export default PropertyWhatsAround;
